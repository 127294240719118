import React from "react"
import { hsl } from "d3-color"

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

function CandidateBox(props) {
  let { name, votes, votesPerc, party, party_color } = props.data
  // const imageURL = name === "Others" ? null : require(`../images/${name}.png`)
  const getLighterColor = color => {
    let c = hsl(color)
    c.l += 0.1
    c.s -= 0.2
    return c.toString()
  }
  return (
    <div
      className={`condidates_box ${party
        .toLocaleLowerCase()
        .split(" ")
        .join("-")}`}
    >
      {/* <div className={"candidate-avatar"} style={_candidateStyle}>
        <img src={imageURL} alt="" srcSet="" height="100%" />
      </div> */}
      <div className={"condidate-name"}>
        {name.split(" ").length > 1 ? (
          <>
            <small>{name.split(" ")[0]}</small>
            {name.split(" ")[1] +
              " " +
              (name.split(" ")[2] ? name.split(" ")[2] : "")}
          </>
        ) : (
          <>{name}</>
        )}
      </div>
      <div className="results">
        <div
          className="votes"
          style={
            party_color && {
              backgroundColor: party_color,
              color: "white",
            }
          }
        >
          {numberWithCommas(votes)}{" "}
        </div>
        <div
          className="percent"
          style={
            party_color && {
              backgroundColor: getLighterColor(party_color),
              color: "white",
            }
          }
        >
          {votesPerc.toLocaleString("en-us", {
            minimumIntegerDigits: 2,
            minimumFractionDigits: 2,
          })}{" "}
          <small>%</small>
        </div>
      </div>
    </div>
  )
}

export default CandidateBox
