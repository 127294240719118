import React, { useState, useEffect } from "react"
import Map from "../components/Map"
import "../styles/main.css"
import StateStats from "../components/StateStats"
import CountyStats from "../components/CountyStats"
import exitFullscreen from "../images/exit-fullscreen.png"
import fullscreen from "../images/fullscreen.png"
import PrimaryModeSwitch from "../components/PrimaryModeSwitch"
import "bootstrap/dist/css/bootstrap.min.css"
// import candidates from "../condidates-1.json"

function makeFullScreen() {
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  ) {
    document.querySelector(".fullscreen").style.background =
      'url("/images/fullscreen.png") center / contain no-repeat content-box'
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  } else {
    var element = document.querySelector(".map_container")
    document.querySelector(".fullscreen").style.background =
      'url("/images/exit-fullscreen.png") center / contain no-repeat content-box'
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }
  }
}

function MapPage({
  location,
  pageContext: { data, state, countyColors, candidates, primaryMode },
}) {
  //==================================
  //  newCountiesColor = {
  // [countyName]: "#cc0008"
  // }
  // newEleData = [
  //   {
  //     Biden: 1438
  //     County: "Barbour"
  //     Trump: 5094
  //   }, ...
  // ]
  //==================================

  const [selectedCounty, setSelectedCounty] = useState(data[0])
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [primaryModeState, setPrimaryModeState] = useState(primaryMode)
  return (
    <div className="map_container">
      <span
        className="fullscreen"
        onClick={e => {
          makeFullScreen()
          setIsFullScreen(c => !c)
        }}
      >
        <img
          src={isFullScreen ? exitFullscreen : fullscreen}
          alt=""
          srcSet=""
          height="100%"
        />
      </span>
      <div className="stats_container">
        <StateStats state={state} candidates={candidates.state} />
        <CountyStats
          selectedCounty={selectedCounty}
          data={candidates.counties}
        />
      </div>
      <div className="map">
        <Map
          state={state}
          setSelectedCounty={setSelectedCounty}
          data={data}
          // newData={counties}
          countiesColor={countyColors}
        />
      </div>
      {primaryMode && (
        <PrimaryModeSwitch
          path={location.pathname}
          activeMode={primaryModeState}
          setMode={setPrimaryModeState}
        />
      )}
    </div>
  )
}

export default MapPage
