import React, { useEffect, useState } from "react"
import Candidate from "./CandidateBox"
const states = require("../states.json")

function StateStats({ state, candidates }) {
  const [stateData, setStateData] = useState()
  // const [condidates, setCondidates] = useState([])

  useEffect(() => {
    if (candidates && state) {
      setStateData({
        candidates: candidates.sort((a, b) => b.votes - a.votes),
        state: states.find(s => s.Code === state).State,
      })
      // setStateData(data)
    }
  }, [])

  if (!stateData) return "loading"
  return (
    <div className="stats_box">
      <div className="state_name">{stateData.state}</div>
      <div>
        {stateData.candidates.map((con, i) => (
          <Candidate key={i} data={con} />
        ))}
      </div>
    </div>
  )
}

export default StateStats
