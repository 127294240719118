import React, { useEffect, useState } from "react"
import Candidate from "./CandidateBox"

function StateStats({ selectedCounty, data }) {
  const [countyData, setCountyData] = useState()
  // console.log(data)
  useEffect(() => {
    if (selectedCounty) {
      const county = data[selectedCounty.County]
      setCountyData({
        candidates: county.sort((a, b) => b.votes - a.votes),
        county: selectedCounty.County,
      })
    }
  }, [selectedCounty])

  if (!countyData)
    return (
      <div
        style={{
          width: "30%",
          height: "200px",
        }}
      ></div>
    )
  return (
    <div className="stats_box">
      <div className="state_name">{countyData.county}</div>
      <div>
        {countyData.candidates.map((con, i) => (
          <Candidate key={i} data={con} />
        ))}
      </div>
    </div>
  )
}

export default StateStats
