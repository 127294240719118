import React, { useState, useEffect } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highchartsMap from "highcharts/modules/map"
import fullScreen from "highcharts/modules/full-screen"

if (typeof Highcharts === "object") {
  highchartsMap(Highcharts)
  fullScreen(Highcharts)
}

const WorldMap = ({ state = "NY", setSelectedCounty, data, countiesColor }) => {
  const [mapOptions, setMapOptions] = useState(null)

  useEffect(() => {
    const stateMapData = require(`@highcharts/map-collection/countries/us/us-${state.toLowerCase()}-all.geo.json`)
    setMapOptions({
      chart: {
        map: `countries/us/us-${state.toLowerCase()}-all`,
        margin: 0,
        height: "600px",
        renderTo: "chart",
        backgroundColor: "#f4f4f4",
      },
      title: {
        text: null,
      },

      subtitle: {
        text: "",
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: "bottom",
        },
      },

      series: [
        {
          mapData: stateMapData,
          data: data.map(c => {
            return { ...c, color: countiesColor[c.County] }
          }),
          joinBy: ["name", "County"],
          name: "Election data",
          dataLabels: {
            enabled: true,
            format: "{point.County}",
          },
          events: {
            click: function(e) {
              const data = { ...e.point.options }
              delete data.color
              setSelectedCounty(data)
            },
          },
        },
      ],
      legend: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        formatter: function(d) {
          const data = { ...this.point.options }
          const name = data.County
          // console.log(data)
          delete data.Val
          delete data.NoCandidates
          delete data.fips
          delete data.County
          delete data["Grand Total"]
          delete data.color
          delete data.value

          const electData = Object.keys(data)
            .map(candidate => ({
              candidate: candidate,
              x: +data[candidate],
            }))
            .sort((a, b) => b.x - a.x)

          return [
            `<b>${name}</b> <br>`,
            ...electData.map(d => `${d.candidate}: ${d.x} <br>`),
          ]
        },
        backgroundColor: "#fff",
        useHTML: true,
        valueDecimals: 2,
        shadow: false,
        shape: "square",
      },
    })
  }, [state, countiesColor])

  return (
    <>
      <HighchartsReact
        constructorType={"mapChart"}
        highcharts={Highcharts}
        options={mapOptions}
      />
    </>
  )
}

export default WorldMap
