import { navigate, Link } from "gatsby"
import React from "react"
import { primaryModes } from "../utils/constants"

const PrimaryModeSwitch = ({ activeMode, setMode, path }) => {
  const btnClass = mode => {
    if (mode === activeMode) {
      return "btn btn-dark"
    } else {
      return "btn btn-outline-dark"
    }
  }
  const handleClick = e => {
    const name = e.target.name
    const path = window.location.pathname
    if (name !== activeMode) {
      navigate(path.replace(activeMode, name))
    }
  }
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: "5rem",
        margin: "5px",
        textDecoration: "none",
      }}
    >
      <div class="btn-group" role="group">
        <Link
          className={btnClass("head2head")}
          to={path.replace(activeMode, "head2head")}
          style={{ textDecoration: "none" }}
        >
          Head to Head
        </Link>

        <Link
          className={btnClass("dem2rep")}
          to={path.replace(activeMode, "dem2rep")}
          style={{ textDecoration: "none" }}
        >
          Dem vs. Rep
        </Link>

        <Link
          className={btnClass("dem2dem")}
          to={path.replace(activeMode, "dem2dem")}
          style={{ textDecoration: "none" }}
        >
          Dem vs. Dem
        </Link>

        <Link
          className={btnClass("rep2rep")}
          to={path.replace(activeMode, "rep2rep")}
          style={{ textDecoration: "none" }}
        >
          Rep vs. Rep
        </Link>
      </div>
    </div>
  )
}

export default PrimaryModeSwitch
